import {Component, OnInit} from '@angular/core';
import {AgRendererComponent} from '@ag-grid-community/angular';
import {ICellRendererParams} from '@ag-grid-community/core';
import {MedispanTrackerResponse} from '../../model/MedispanTrackerResponse';
import {PRODUCT_OVERVIEW_SOURCE} from '../../enum/ProductOverviewSource';
import {GRID_DATA} from '../../enum/GridData';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-custom-navigation',
    templateUrl: './custom-navigation.component.html',
    styleUrls: ['./custom-navigation.component.scss']
})
export class CustomNavigationComponent implements OnInit, AgRendererComponent {
    paramsInNavigationComponent: ICellRendererParams & { source: PRODUCT_OVERVIEW_SOURCE};
    medispanTrackerData: MedispanTrackerResponse;
    adhocData;
    navigationSource: string;
    source: PRODUCT_OVERVIEW_SOURCE;
    showChildDetailLink = false;
    mainTabName = '';
    subTabIndex = -1;
    navigationParam;
    selectedLOBS = [];

    constructor(private _matDialogService: MatDialog) {}

    ngOnInit(): void {
    }

    agInit(params: (ICellRendererParams & {source: PRODUCT_OVERVIEW_SOURCE; mainTabName: string; subTabIndex: number;
        navigatedFromLink: string; selectedLOBS: string[];})): void {
        this.showChildDetailLink = params.value === GRID_DATA.DETAIL_LINK;
        this.source = params.source;
        if (this.source === PRODUCT_OVERVIEW_SOURCE.GPI_TO_NDC_ADHOC) {
            this.adhocData = params.data;
        } else {
            this.medispanTrackerData = params.data?.drugMasterResponse || params.data;
        }
        this.paramsInNavigationComponent = params;
        this.mainTabName = params.mainTabName;
        this.subTabIndex = params.subTabIndex;
        if (params.navigatedFromLink) {
            this.navigationSource = params.navigatedFromLink;
        }

        if (this.source === PRODUCT_OVERVIEW_SOURCE.FORMULARY_SEARCH && params.selectedLOBS !== undefined) {
            this.selectedLOBS = params.selectedLOBS;
        }

        const subTab = this.subTabIndex? this.subTabIndex.toString() : '';
        this.navigationParam = btoa(this.source + subTab + params.rowIndex);
    }

    refresh(params: ICellRendererParams): boolean {
        return false;
    }

    onCellClick(event) {
        this._matDialogService.closeAll();
        if (this.source === PRODUCT_OVERVIEW_SOURCE.FORMULARY_SEARCH) {
            if (this.medispanTrackerData !== null &&
                    ( this.medispanTrackerData.ndc === null || this.medispanTrackerData.ndc === '')) {
                if (event !== null && event.target !== null) {
                    this.medispanTrackerData.ndc = event.target.innerText;
                }
            }
        }
    }

    onShowChildDetailLink() {
        this.paramsInNavigationComponent.node.setExpanded(!this.paramsInNavigationComponent.node.expanded);
    }
}
